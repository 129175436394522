import { LinearProgress } from "@material-ui/core";
import React from 'react';
import Square from "../Square/Square";
import { BoardContext, IBoardContext } from "./board.context";

export interface IBoardProps {

}

export interface IBoardState {
}

export default class Board extends React.Component<IBoardProps, IBoardState> {
	public static contextType = BoardContext;
	constructor(props: IBoardProps, state: IBoardState) {
		super(props);
		this.state = {
		}
		this.boardContext = this.context;
	}

	private boardContext: IBoardContext;

	public componentDidMount() {
		this.boardContext.generateBoard();
	}

	public componentWillUnmount() {

	}

	public render() {
		this.boardContext = this.context;
		const { rowCount, columnCount, squares, mines } = this.boardContext;
		const { innerHeight, innerWidth } = window;
		const squareCount = (rowCount * columnCount) - mines.length;
		const progress = (squares.filter((item) => item.clicked && !item.isMine).length / squareCount) * 100;
		return (
			<div tabIndex={0} onKeyDown={(event) => console.log(event.keyCode)}>
				<LinearProgress variant="determinate" value={progress} />
				<div
					style={{
						display: "grid",
						gridTemplateColumns: "repeat(" + columnCount + ", calc(" + innerWidth + "px / " + columnCount + ")",
						gridTemplateRows: "repeat(" + rowCount + ", 1fr)",
						justifyItems: "center",
						alignItems: "center",
						height: "calc(" + innerHeight + "px - 4rem - 4px)",
						width: innerWidth,
					}}
				>
					{
						squares.map((square, index) => (
							<Square
								key={index}
								info={square}
							/>
						))
					}
				</div>
			</div>
		);
	}
}