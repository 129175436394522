import * as React from "react";
import { AppContext, IAppContext } from "../App/app.context";
import { AdventureMan } from "../Square/AdventureMan";
import { SquareInfo } from "../Square/SquareInfo";
import BreadthFirstSearch from "./BreadthFirstSearch";

export interface IBoardProviderState {
	mines: number[];
	squares: SquareInfo[];
	win: boolean;
	primaryClick: "mine" | "flag";
	xDown: number | null;
	yDown: number | null;
	adventureMan: AdventureMan;
	adventureMode: boolean;
	adventurePath: SquareInfo[];
}

export interface IBoardContext extends IBoardProviderState {
	rowCount: number;
	columnCount: number;
	checkClick(info: SquareInfo): void;
	generateBoard(): void;
	setFlag(info: SquareInfo): void;
}

export const BoardContext = React.createContext<IBoardContext>(
	{
		rowCount: 0,
		columnCount: 0,
		mines: [],
		squares: [],
		win: false,
		primaryClick: "mine",
		xDown: null,
		yDown: null,
		adventureMan: new AdventureMan(),
		adventureMode: false,
		adventurePath: [],
		checkClick: () => null,
		generateBoard: () => null,
		setFlag: () => null
	}
);

export interface IBoardProviderProps {

}


export default class BoardProvider extends React.Component<IBoardProviderProps, IBoardProviderState> {
	public static contextType = AppContext;
	constructor(props: IBoardProviderProps, state: IBoardProviderState) {
		super(props);
		this.state = {
			mines: [],
			squares: [],
			primaryClick: "mine",
			win: false,
			xDown: null,
			yDown: null,
			adventureMan: new AdventureMan(),
			adventureMode: false,
			adventurePath: [],
		}
		this.appContext = this.context;
	}

	private appContext: IAppContext;
	private setarrow = this.setArrowMove.bind(this);
	private touchStart = this.setTouchStart.bind(this);
	private touchMove = this.setTouchMove.bind(this);
	public componentDidMount(): void {
		document.addEventListener("keydown", this.setarrow, false);
		document.addEventListener('touchstart', this.touchStart, false);
		document.addEventListener("touchmove", this.touchMove, { passive: false });
	}

	public componentWillUnmount() {
		document.removeEventListener("keydown", this.setarrow, false);
		document.removeEventListener('touchstart', this.touchStart, false);
		document.removeEventListener("touchmove", this.touchMove);
	}

	public render() {
		this.appContext = this.context;

		return (
			<BoardContext.Provider value={{
				rowCount: this.appContext.rows,
				columnCount: this.appContext.cols,
				mines: this.state.mines,
				squares: this.state.squares,
				win: this.appContext.gameWon,
				primaryClick: this.appContext.primaryClick,
				xDown: null,
				yDown: null,
				adventureMan: this.state.adventureMan,
				adventureMode: this.appContext.adventureMode,
				adventurePath: this.state.adventurePath,
				generateBoard: () => this.generateBoard(),
				checkClick: (info) => this.checkClick(info),
				setFlag: (info) => this.setFlag(info)
			}}>
				{this.props.children}
			</BoardContext.Provider>
		);
	}

	private setFlag(info: SquareInfo) {
		if (!this.appContext.gameOver) {
			if (this.appContext.vibration) {
				if (window.navigator.vibrate)
					window.navigator.vibrate(100);
			}
			let squares = this.state.squares;
			let flags = this.appContext.flags;
			if (!info.hasFlag) {
				flags--;
				squares[info.index].hasFlag = true;
			} else if (info.hasFlag) {
				flags++;
				squares[info.index].hasFlag = false;
			}

			this.setState({
				squares
			}, () => this.appContext.updateFlags(flags));
		}
	}

	private generateBoard() {
		this.appContext.setLoading(true);
		let mines = this.generateMines();
		this.setState({
			mines,
			adventureMan: new AdventureMan()
		}, () => {
			this.generateSquares();
			this.appContext.setLoading(false);
		});
	}

	private generateMines() {
		let mines: number[] = [];
		let totalSquares = this.appContext.rows * this.appContext.cols;
		for (let i = 0; i < this.appContext.mines; i++) {
			const mine = Math.floor(Math.random() * totalSquares);
			if (mines.indexOf(mine) === -1) {
				if (this.appContext.adventureMode && (mine === 0 || (mine === totalSquares - 1))) {
					i--;
				} else {
					mines.push(mine);
				}
			} else {
				i--;
			}
		}
		return mines;
	}

	private generateSquares() {
		const { mines } = this.state;
		let squares: SquareInfo[] = [];
		let count = 0;
		for (let r = 0; r < this.appContext.rows; r++) {
			for (let c = 0; c < this.appContext.cols; c++) {
				let index = count;
				squares.push(
					new SquareInfo({
						row: r,
						column: c,
						index,
						isMine: mines.some((mine) => mine === index),
						touches: 0
					})
				);
				count++;
			}
		}
		squares = this.getTouches(squares);
		if (this.appContext.adventureMode) {
			let bfs = new BreadthFirstSearch();
			let adventurePath = bfs.isBoardSolvable(squares, this.appContext.cols, this.appContext.rows);
			if (adventurePath.length === 0) {
				console.log("Not solvable, retrying");
				this.generateBoard();
			} else {
				squares[0].clicked = true;
				this.setState({
					squares,
					adventurePath
				});
			}
		} else {
			this.setState({
				squares
			});
		}
	}

	private getTouches(squares: SquareInfo[]) {
		squares.forEach(square => {
			let touches = 0;
			let mineSquares = squares.filter((item) => item.isMine === true);
			let row = square.row;
			let col = square.column;
			mineSquares.map((mine) => {
				if (mine.row === row) {
					if (mine.column === col + 1 ||
						mine.column === col - 1) {
						touches++;
					}
				}
				if (mine.row === row + 1) {
					if (mine.column === col + 1 ||
						mine.column === col ||
						mine.column === col - 1) {
						touches++;
					}
				}
				if (mine.row === row - 1) {
					if (mine.column === col + 1 ||
						mine.column === col ||
						mine.column === col - 1) {
						touches++;
					}
				}

			});
			square.touches = touches;
		});
		return squares;
	}

	private checkClick(clickedSquare: SquareInfo) {
		let squares = this.state.squares;
		if (!this.appContext.gameOver) {
			if (!clickedSquare.isMine) {
				if (clickedSquare.touches === 0) {
					let surrounding = [
						[clickedSquare.column, clickedSquare.row],
						[clickedSquare.column, clickedSquare.row + 1],
						[clickedSquare.column, clickedSquare.row - 1],
						[clickedSquare.column + 1, clickedSquare.row],
						[clickedSquare.column - 1, clickedSquare.row],
					];

					surrounding.forEach((item) => {
						let s = squares.find((square) => item[0] === square.column && item[1] === square.row);
						if (s && !s.clicked && !s.isMine && !s.hasFlag) {
							s.clicked = true;
							squares[s.index] = s;
							this.setState({
								squares,
							}, () => {
								this.checkClick(s!);
							});
						}
					});
				} else {
					clickedSquare.clicked = true;
					squares[clickedSquare.index] = clickedSquare;
					this.setState({
						squares
					});
				}
				this.checkForWin();
			} else {
				if (this.appContext.vibration)
					if (window.navigator.vibrate)
						window.navigator.vibrate(500);
				const { mines } = this.state;
				clickedSquare.clicked = true;
				clickedSquare.exploded = true;
				squares[clickedSquare.index] = clickedSquare;
				mines.forEach(mine => {
					squares[mine].clicked = true;
				});
				this.setState({
					squares
				}, () => this.appContext.gameEnded(false));
			}
		}
	}

	private checkForWin() {
		const { squares, mines } = this.state;
		let squaresLeft = squares.filter((square) => !square.clicked);
		if (squaresLeft.length === mines.length) {
			if (this.appContext.vibration)
				if (window.navigator.vibrate)
					window.navigator.vibrate([100, 50, 1000]);
			this.appContext.gameEnded(true);
		}
	}

	private checkAdventureWin() {
		const { squares, adventureMan } = this.state;
		if (adventureMan.index === squares.length - 1) {
			if (this.appContext.vibration)
				if (window.navigator.vibrate)
					window.navigator.vibrate([100, 50, 1000]);
			this.appContext.gameEnded(true);
		}
	}

	private setArrowMove(event: KeyboardEvent) {
		if (this.appContext.adventureMode && !this.appContext.gameOver)
			switch (event.key) {
				case "ArrowUp":
					this.moveAdventureMan(Move.Up);
					break;
				case "ArrowDown":
					this.moveAdventureMan(Move.Down);
					break;
				case "ArrowLeft":
					this.moveAdventureMan(Move.Left);
					break;
				case "ArrowRight":
					this.moveAdventureMan(Move.Right);
					break;

				default:
					break;
			}
	}

	private setTouchStart(event: TouchEvent) {
		if (this.appContext.adventureMode && !this.appContext.gameOver)
			this.setState({
				xDown: event.touches[0].clientX,
				yDown: event.touches[0].clientY
			});
	}

	private setTouchMove(event: TouchEvent) {
		event.preventDefault();
		if (this.appContext.adventureMode && !this.appContext.gameOver) {
			let { xDown, yDown } = this.state;
			if (!xDown || !yDown) {
				return;
			}

			const xUp = event.touches[0].clientX;
			const yUp = event.touches[0].clientY;

			const xDelta = xDown - xUp;
			const yDelta = yDown - yUp;

			if (Math.abs(xDelta) > Math.abs(yDelta)) {
				if (xDelta > 0) {
					this.moveAdventureMan(Move.Left);
				} else {
					this.moveAdventureMan(Move.Right);
				}
			} else {
				if (yDelta > 0) {
					this.moveAdventureMan(Move.Up);
				} else {
					this.moveAdventureMan(Move.Down);
				}
			}
		}
	}

	private moveAdventureMan(move: Move) {
		let { adventureMan } = this.state;
		const { rows, cols } = this.appContext;
		switch (move) {
			case Move.Up:
				if (adventureMan.row > 0 && !this.indexHasFlag(adventureMan.index - cols)) {
					adventureMan.index = adventureMan.index - cols;
					adventureMan.row--;
				}
				break;
			case Move.Down:
				if (adventureMan.row < rows - 1 && !this.indexHasFlag(adventureMan.index + cols)) {
					adventureMan.index = adventureMan.index + cols;
					adventureMan.row++;
				}
				break;
			case Move.Left:
				if (adventureMan.column > 0 && !this.indexHasFlag(adventureMan.index - 1)) {
					adventureMan.index--;
					adventureMan.column--;
				}
				break;
			case Move.Right:
				if (adventureMan.column < cols - 1 && !this.indexHasFlag(adventureMan.index + 1)) {
					adventureMan.index++;
					adventureMan.column++;
				}
				break;

			default:
				break;
		}
		this.setState({
			xDown: null,
			yDown: null,
			adventureMan
		}, () => {
			this.checkClick(this.state.squares[adventureMan.index]);
			this.checkAdventureWin();
		});
	}

	private indexHasFlag(index: number) {
		return this.state.squares[index].hasFlag;
	}
}

export enum Move {
	Up,
	Down,
	Left,
	Right
}