import { AppBar, Button, createStyles, Dialog, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Tab, Tabs, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as firebase from "firebase/app";
import "firebase/firestore";
import React from 'react';
import { AppContext, IAppContext } from "../App/app.context";
import { UserScore } from "../App/UserScores";

const styles = (theme: Theme) => createStyles({
	score: {
		display: "grid",
		justifyItems: "center",
		alignItems: "center",
		gridTemplateColumns: "3rem 1fr",
		gridTemplateRows: "1fr 1fr 1fr",
		gridTemplateAreas: "'place time' 'place name' 'place date'",
		border: "1px solid #ffffff33",
		borderRadius: "10px",
		padding: "0.5rem"
	},
	scores: {
		display: "grid",
		height: "100%",
		margin: "1rem 0",
		gridGap: "1rem",
		alignContent: "start"
	},
	stats: {
		alignSelf: "end"
	},
	place: {
		gridArea: "place"
	},
	time: {
		gridArea: "time"
	},
	name: {
		gridArea: "name",
		color: "#ffffff77"
	},
	date: {
		gridArea: "date",
		color: "#ffffff77"
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120,
	},
});

export interface IHighScoresProps extends WithStyles<typeof styles> {
	open: boolean;
	close(): void;
}

export interface IHighScoresState {
	beginner: UserScore[];
	intermediate: UserScore[];
	expert: UserScore[];
	tab: number;
	mode: string;
}

export class GameHighScores extends React.Component<IHighScoresProps, IHighScoresState> {
	public static contextType = AppContext;
	constructor(props: IHighScoresProps, state?: IHighScoresState) {
		super(props);
		this.state = {
			beginner: [],
			intermediate: [],
			expert: [],
			tab: 0,
			mode: "score"
		}
		this.appContext = this.context;
		this.beginner = () => null;
		this.intermediate = () => null;
		this.expert = () => null;
	}

	private appContext: IAppContext;
	private beginner: firebase.Unsubscribe | null;
	private intermediate: firebase.Unsubscribe | null;
	private expert: firebase.Unsubscribe | null;

	public componentDidMount(): void {
		console.log(this.appContext.user);

		if (this.appContext.user) {
			this.subscribeData("score");
		}
	}

	public componentWillUnmount() {
		this.unsubscribeData();
	}

	public render() {
		this.appContext = this.context;
		const { beginner, intermediate, expert, tab, mode } = this.state;
		const { classes } = this.props;
		const colors = [
			"gold",
			"silver",
			"sandybrown",
			"#ffffff22",
			"#ffffff22"
		];

		return (
			<Dialog
				fullScreen={true}
				open={this.props.open}
				onClose={() => this.props.close()}
			>
				<AppBar position="static" color={"inherit"}>
					<Typography style={{ padding: "1rem" }} variant={"h5"}>Leaderboard</Typography>
					<FormControl variant="outlined" className={classes.formControl}>
						<InputLabel
							htmlFor={"mode"}
						>
							Mode
          				</InputLabel>
						<Select
							value={mode}
							onChange={(event) => this.subscribeData(event.target.value)}
							input={
								<OutlinedInput
									labelWidth={50}
									name="mode"
									id={"mode"}
								/>
							}
						>
							<MenuItem value={"score"}>Classic Mode</MenuItem>
							<MenuItem value={"adventureScore"}>Adventure Mode</MenuItem>
						</Select>
					</FormControl>
					<Tabs value={tab} onChange={(event, tab) => this.setState({ tab })}>
						<Tab style={{ color: "white" }} label="Beginner" />
						<Tab style={{ color: "white" }} label="Intermediate" />
						<Tab style={{ color: "white" }} label="Expert" />
					</Tabs>
				</AppBar>
				<DialogContent>
					{
						tab === 0 &&
						<div>
							<div className={classes.scores}>
								{
									beginner.map((item, index) =>
										<div key={index} className={classes.score} style={{ borderColor: colors[index] }}>
											<Typography style={{ color: colors[index] }} className={classes.place} variant={"h6"}>{(index + 1).toString()}</Typography>
											<Typography className={classes.time} variant={"body1"}>{mode === "score" ? item.score.toFixed(3) : item.adventureScore ? item.adventureScore.toFixed(3) : ""}</Typography>
											<Typography className={classes.name} variant={"subtitle2"}>{item.name}</Typography>
											<Typography className={classes.date} variant={"caption"}>{mode === "score" ? item.timestamp.toDate().toLocaleString() : item.adventureTimestamp ? item.adventureTimestamp.toDate().toLocaleString() : ""}</Typography>
										</div>
									)
								}
							</div>
							{
								mode === "score" &&
								<div className={classes.stats}>
									<Typography style={{ borderBottom: "1px solid #ffffff33", marginBottom: "0.5rem" }} variant={"h6"}>Stats:</Typography>
									<Typography variant={"body1"}>Your Best: {this.appContext.beginner.score ? this.appContext.beginner.score.toFixed(3) : "-"}</Typography>
									<Typography variant={"body1"}>Games: {this.appContext.beginner.games || 0}</Typography>
									<Typography variant={"body1"}>Wins: {this.appContext.beginner.wins || 0}</Typography>
									<Typography variant={"body1"}>%: {((this.appContext.beginner.wins / this.appContext.beginner.games) * 100 || 0).toFixed(2)}</Typography>
								</div>
							}
						</div>
					}
					{
						tab === 1 &&
						<div>
							<div className={classes.scores}>
								{
									intermediate.map((item, index) =>
										<div key={index} className={classes.score} style={{ borderColor: colors[index] }}>
											<Typography style={{ color: colors[index] }} className={classes.place} variant={"h6"}>{(index + 1).toString()}</Typography>
											<Typography className={classes.time} variant={"body1"}>{mode === "score" ? item.score.toFixed(3) : item.adventureScore ? item.adventureScore.toFixed(3) : ""}</Typography>
											<Typography className={classes.name} variant={"subtitle2"}>{item.name}</Typography>
											<Typography className={classes.date} variant={"caption"}>{mode === "score" ? item.timestamp.toDate().toLocaleString() : item.adventureTimestamp ? item.adventureTimestamp.toDate().toLocaleString() : ""}</Typography>
										</div>
									)
								}
							</div>
							{
								mode === "score" &&
								<div className={classes.stats}>
									<Typography style={{ borderBottom: "1px solid #ffffff33", marginBottom: "0.5rem" }} variant={"h6"}>Stats:</Typography>
									<Typography variant={"body1"}>Your Best: {this.appContext.intermediate.score ? this.appContext.intermediate.score.toFixed(3) : "-"}</Typography>
									<Typography variant={"body1"}>Games: {this.appContext.intermediate.games || 0}</Typography>
									<Typography variant={"body1"}>Wins: {this.appContext.intermediate.wins || 0}</Typography>
									<Typography variant={"body1"}>%: {((this.appContext.intermediate.wins / this.appContext.intermediate.games) * 100 || 0).toFixed(2)}</Typography>
								</div>
							}
						</div>
					}
					{
						tab === 2 &&
						<div>
							<div className={classes.scores}>
								{
									expert.map((item, index) =>
										<div key={index} className={classes.score} style={{ borderColor: colors[index] }}>
											<Typography style={{ color: colors[index] }} className={classes.place} variant={"h6"}>{(index + 1).toString()}</Typography>
											<Typography className={classes.time} variant={"body1"}>{mode === "score" ? item.score.toFixed(3) : item.adventureScore ? item.adventureScore.toFixed(3) : ""}</Typography>
											<Typography className={classes.name} variant={"subtitle2"}>{item.name}</Typography>
											<Typography className={classes.date} variant={"caption"}>{mode === "score" ? item.timestamp.toDate().toLocaleString() : item.adventureTimestamp ? item.adventureTimestamp.toDate().toLocaleString() : ""}</Typography>
										</div>
									)
								}
							</div>
							{
								mode === "score" &&
								<div className={classes.stats}>
									<Typography style={{ borderBottom: "1px solid #ffffff33", marginBottom: "0.5rem" }} variant={"h6"}>Stats:</Typography>
									<Typography variant={"body1"}>Your Best: {this.appContext.expert.score ? this.appContext.expert.score.toFixed(3) : "-"}</Typography>
									<Typography variant={"body1"}>Games: {this.appContext.expert.games || 0}</Typography>
									<Typography variant={"body1"}>Wins: {this.appContext.expert.wins || 0}</Typography>
									<Typography variant={"body1"}>%: {((this.appContext.expert.wins / this.appContext.expert.games) * 100 || 0).toFixed(2)}</Typography>
								</div>
							}
						</div>
					}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => this.props.close()} color="secondary">
						Close
            			</Button>
				</DialogActions>
			</Dialog>
		);
	}

	private subscribeData(mode: string) {
		this.setState({
			beginner: [],
			intermediate: [],
			expert: [],
			mode
		}, () => {
			this.unsubscribeData();
			this.beginner = firebase.firestore().collection("beginner").where(mode, ">", 0).orderBy(mode, "asc").limit(5).onSnapshot((snapshot) => this.onBeginnerScoresUpdated(snapshot));
			this.intermediate = firebase.firestore().collection("intermediate").where(mode, ">", 0).orderBy(mode, "asc").limit(5).onSnapshot((snapshot) => this.onIntermediateScoresUpdated(snapshot));
			this.expert = firebase.firestore().collection("expert").where(mode, ">", 0).orderBy(mode, "asc").limit(5).onSnapshot((snapshot) => this.onExpertScoresUpdated(snapshot));
		});
	}

	private unsubscribeData() {
		if (this.beginner) {
			this.beginner();
		}
		if (this.intermediate) {
			this.intermediate();
		}
		if (this.expert) {
			this.expert();
		}
	}

	private onBeginnerScoresUpdated(snapshot: firebase.firestore.QuerySnapshot) {
		let beginner: UserScore[] = [];
		snapshot.forEach(doc => {
			beginner.push(doc.data() as UserScore);
		});
		this.setState({
			beginner
		});
	}

	private onIntermediateScoresUpdated(snapshot: firebase.firestore.QuerySnapshot) {
		let intermediate: UserScore[] = [];
		snapshot.forEach((doc) => {
			intermediate.push(doc.data() as UserScore);
		});
		this.setState({
			intermediate
		});
	}

	private onExpertScoresUpdated(snapshot: firebase.firestore.QuerySnapshot) {
		let expert: UserScore[] = [];
		snapshot.forEach((doc) => {
			expert.push(doc.data() as UserScore);
		});
		this.setState({
			expert
		});
	}
}

export default withStyles(styles)(GameHighScores);