import { createStyles, SvgIcon, Theme, WithStyles, withStyles } from "@material-ui/core";
import { blue, deepPurple, green, grey, indigo, purple, red, teal, yellow } from "@material-ui/core/colors";
import FlagIcon from '@material-ui/icons/Flag';
import HomeIcon from '@material-ui/icons/Home';
import React from 'react';
import { BoardContext, IBoardContext } from "../Board/board.context";
import { SquareInfo } from "./SquareInfo";

const bomb = "M11.25,6A3.25,3.25 0 0,1 14.5,2.75A3.25,3.25 0 0,1 17.75,6C17.75,6.42 18.08,6.75 18.5,6.75C18.92,6.75 19.25,6.42 19.25,6V5.25H20.75V6A2.25,2.25 0 0,1 18.5,8.25A2.25,2.25 0 0,1 16.25,6A1.75,1.75 0 0,0 14.5,4.25A1.75,1.75 0 0,0 12.75,6H14V7.29C16.89,8.15 19,10.83 19,14A7,7 0 0,1 12,21A7,7 0 0,1 5,14C5,10.83 7.11,8.15 10,7.29V6H11.25M22,6H24V7H22V6M19,4V2H20V4H19M20.91,4.38L22.33,2.96L23.04,3.67L21.62,5.09L20.91,4.38Z";

const styles = (theme: Theme) => createStyles({
	square: {
		width: "100%",
		height: "100%",
		border: "2px solid " + theme.palette.background.default,
		justifyItems: "center",
		alignItems: "center",
		display: "grid",
		userSelect: "none"
	},
});

export interface ISquareProps extends WithStyles<typeof styles> {
	info: SquareInfo;
}

export interface ISquareState {
	longPress: boolean;
}

export class Square extends React.Component<ISquareProps, ISquareState> {
	public static contextType = BoardContext;
	constructor(props: ISquareProps, state?: ISquareState) {
		super(props);
		this.state = {
			longPress: false
		}
		this.boardContext = this.context;
	}

	private boardContext: IBoardContext;

	public componentDidMount() {

	}

	public componentDidUpdate(prevProps: ISquareProps, prevState: ISquareState) {

	}

	public render() {
		this.boardContext = this.context;
		const { mines, adventureMan } = this.boardContext;
		const { info, classes } = this.props;
		let color = grey[500];
		let textColor = "white";
		let fontSize = ((1 / this.boardContext.rowCount) * 300) + "px";
		if (info.clicked) {
			color = mines.some((mine) => mine === info.index) ? red[500] : "black";
			color = info.exploded ? red[900] : color;
			textColor = this.getNumberColor(info.touches);
		}
		let text = info.touches > 0 ? info.touches.toString() : "";
		if (info.isMine) {
			textColor = "white";
		}
		if (info.hasFlag) color = grey[700];

		if (this.boardContext.adventureMode && info.index === this.boardContext.squares.length - 1) {
			color = green[500];
		}
		if (this.boardContext.adventureMode && this.boardContext.win && info.showPath) {
			color = indigo[500];
			textColor = "white";
		}
		return (
			<div
				onTouchStart={() => {
					this.setState({ longPress: true });
					setTimeout(() => {
						if (this.state.longPress) this.secondaryClick(info);
					}, 500);
				}}
				onTouchMove={() => this.setState({ longPress: false })}
				onTouchEnd={() => this.setState({ longPress: false })}
				onContextMenu={(e) => {
					e.preventDefault();
					if (!navigator.maxTouchPoints) this.secondaryClick(info);
				}}
				key={info.index}
				style={{
					backgroundColor: color,
					borderColor: (adventureMan.index === info.index && this.boardContext.adventureMode) ? yellow[500] : ""
				}}
				onClick={() => this.boardContext.primaryClick === "flag"
					? this.boardContext.setFlag(info)
					: this.clicked()}
				className={classes.square}
			>
				<div style={{
					color: textColor,
					fontWeight: 100,
					fontSize: fontSize,
					gridRow: 1,
					gridColumn: 1,
					zIndex: 1
				}}>
					{info.clicked
						? info.isMine
							? <SvgIcon style={{ justifySelf: "center", width: fontSize, height: fontSize }}>
								<path d={bomb} />
							</SvgIcon>
							: text
						: ""}
					{
						info.hasFlag && !info.clicked &&
						<FlagIcon color={"disabled"} style={{ fontSize: fontSize }} />
					}
				</div>
				{
					this.boardContext.adventureMode && (info.index === (this.boardContext.rowCount * this.boardContext.columnCount) - 1) &&
					<HomeIcon style={{
						fontSize: fontSize,
						gridRow: 1,
						gridColumn: 1,
						color: "#ffffff77"
					}} />
				}
			</div >
		);
	}

	private secondaryClick(info: SquareInfo) {
		if (!info.clicked) {
			this.boardContext.primaryClick === "mine"
				? this.boardContext.setFlag(info)
				: this.clicked();
		}
	}

	private clicked() {
		const { info } = this.props;
		if (!info.hasFlag && !this.boardContext.adventureMode) {
			this.boardContext.checkClick(info);
		}
	}

	private getNumberColor(touches: number) {
		switch (touches) {
			case 1:
				return blue[500];
			case 2:
				return green[500];
			case 3:
				return red[500];
			case 4:
				return yellow[500];
			case 5:
				return purple[500];
			case 6:
				return teal[500];
			case 7:
				return deepPurple[500];
			case 8:
				return grey[500];

			default:
				return "white";
		}
	}
}

export default withStyles(styles)(Square);