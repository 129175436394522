import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import Board from "../Board/Board";
import BoardProvider from "../Board/board.context";
import ControlBar from "../ControlBar/ControlBar";
import { IBeforeInstallPromptEvent } from "../helpers/beforeinstall";
import GameMenu from "../Menu/Menu";
import AppProvider, { AppContext, IAppContext } from "./app.context";
import "./App.css";
import icon from "./icon512.png";

export interface IAppProps {

}

export interface IAppState {
	prompt?: IBeforeInstallPromptEvent | Event;
	keys1: string[];
	keys2: string[];
}

export class AppHome extends React.Component<IAppProps, IAppState> {
	public static contextType = AppContext;
	constructor(props: IAppProps, state: IAppState) {
		super(props);
		this.state = {
			keys1: [],
			keys2: []
		}
	}

	private keytest1 = this.keyTest1.bind(this);
	private keytest2 = this.keyTest2.bind(this);

	public componentDidMount() {
		window.addEventListener('appinstalled', (evt) => {
			console.log('a2hs', 'installed');
		});
		window.addEventListener('beforeinstallprompt', (prompt) => {
			console.log("BEFOREINSTALLPROMPT REGISTER");
			if (!/Android/i.test(navigator.userAgent)) {
				this.setState({ prompt });
			}
		});

		document.addEventListener("keydown", this.keytest1, false);
		document.addEventListener("keydown", this.keytest2, false);
	}

	public render() {
		let appContext: IAppContext = this.context;
		return (
			<div style={{ height: window.innerHeight }}>
				{
					this.state.prompt
						? <div className={"installPrompt"}>
							<div className={"installDetailsContainer"}>
								<img className={"installIcon"} src={icon} />
								<div className={"installDetails"}>
									<div className={"installTitle"}>Minesweeper</div>
									<div className={"installURL"}>https://minesweeper.inprod.dev</div>
								</div>
								<div className={"installClose"} onClick={() => this.setState({ prompt: undefined })}><CloseIcon /></div>
							</div>
							<div className={"installButtonContainer"}>
								<div className={"installButton"} onClick={() => this.installApp()}>ADD TO HOME SCREEN</div>
							</div>
						</div>
						: null
				}
				{
					appContext.gameStarted
						?
						<BoardProvider>
							<div>
								<ControlBar />
								<Board />
							</div>
						</BoardProvider>
						: <GameMenu />
				}
			</div>
		);
	}

	private async installApp() {
		const { prompt } = this.state;
		(prompt! as IBeforeInstallPromptEvent).prompt();
		const result = await (prompt! as IBeforeInstallPromptEvent).userChoice;
		if (result.outcome === 'accepted') {
			console.log('User accepted the A2HS prompt');
		} else {
			console.log('User dismissed the A2HS prompt');
		}
		this.setState({ prompt: undefined });
	}

	private keyTest1(event: KeyboardEvent) {
		let keys1 = this.state.keys1;
		keys1.push(event.key);

		if (keys1.length > 7) {
			keys1.shift();
		}

		if (JSON.stringify(keys1) === JSON.stringify(["x", "y", "z", "z", "y", "Shift", "Enter"])) {
			console.log("WINNER");
		}

		this.setState({
			keys1
		});
	}

	private keyTest2(event: KeyboardEvent) {
		let keys2 = this.state.keys2;
		keys2.push(event.key);

		if (keys2.length > 11) {
			keys2.shift();
		}

		if (JSON.stringify(keys2) === JSON.stringify(["ArrowUp", "ArrowUp", "ArrowDown", "ArrowDown", "ArrowLeft", "ArrowRight", "ArrowLeft", "ArrowRight", "b", "a", "Enter"])) {
			console.log("WINNER2");
		}

		this.setState({
			keys2
		});
	}
}

export default class App extends React.Component<IAppProps, IAppState> {
	public render() {
		return (
			<AppProvider>
				<AppHome />
			</AppProvider>
		);
	}
}