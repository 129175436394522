import { Button, createStyles, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { pink } from "@material-ui/core/colors";
import FlagIcon from '@material-ui/icons/Flag';
import React from 'react';
import { version } from "../../package.json";
import { AppContext, IAppContext } from "../App/app.context";
import HighScores from "./HighScores";
import Settings from "./Settings";

const styles = (theme: Theme) => createStyles({
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
	},
	button: {
		margin: theme.spacing.unit * 2,
	},
	formControl: {
		margin: theme.spacing.unit * 2,
	},
	group: {
		margin: 0,
	},
	settings: {
		justifyItems: "center",
		display: "grid",
		padding: "0 3rem"
	},
});

const maxRows = 30;
const maxCols = 24;
const maxMines = ((maxCols - 1) * (maxRows - 1));
const min = 5;

export interface IMenuProps extends WithStyles<typeof styles> {

}

export interface IMenuState {
	settingsOpen: boolean;
	highScoresOpen: boolean;
	viewAccount: boolean;
}

export class GameMenu extends React.Component<IMenuProps, IMenuState> {
	public static contextType = AppContext;
	constructor(props: IMenuProps, state?: IMenuState) {
		super(props);
		this.state = {
			settingsOpen: false,
			highScoresOpen: false,
			viewAccount: false
		}
		this.appContext = this.context;
	}

	private appContext: IAppContext;

	public render() {
		this.appContext = this.context;
		const { difficulty } = this.appContext;
		const { classes } = this.props;

		return (
			<div style={{
				display: "grid",
				justifyItems: "center",
				alignContent: "center",
				height: "100%",
				padding: "2rem",
				gridTemplateColumns: "1fr",
				gridTemplateRows: this.appContext.user ? "1fr auto auto auto auto 1fr" : "1fr auto auto auto auto auto 1fr"
			}}>
				<FlagIcon color={"disabled"} style={{ fontSize: "10rem", margin: "2rem", padding: "1rem", border: "5px solid #ffffff33", borderRadius: "50%" }} />
				<Typography variant={"h4"} style={{ padding: "1rem" }}>
					Minesweeper
				</Typography>
				<div style={{ marginBottom: "2rem", textAlign: "center" }}>
					<Button
						variant={"outlined"}
						color={"default"}
						className={classes.button}
						onClick={() => this.appContext.startGame(false)}
						disabled={this.getDisabled()}
					>
						Classic Mode
      				</Button>
					<Button
						variant={"outlined"}
						color={"default"}
						className={classes.button}
						onClick={() => this.appContext.startGame(true)}
						disabled={this.getDisabled() || difficulty === "custom"}
					>
						Adventure Mode
      				</Button>
					<Typography>Difficulty: {difficulty.toUpperCase()}</Typography>
				</div>
				<Button
					variant={"outlined"}
					color={"secondary"}
					className={classes.button}
					onClick={() => this.setState({ highScoresOpen: true })}
					disabled={!this.appContext.user}
				>
					Leaderboard
      			</Button>
				{
					!this.appContext.user &&
					<div style={{ display: "grid", justifyItems: "center" }}>
						<Typography variant={"caption"} style={{ color: "#ffffff77" }}>Sign in to see leaderboards and stats</Typography>
						<Button
							variant={"text"}
							style={{ marginBottom: "2rem", color: pink[500] }}
							onClick={() => this.setState({
								settingsOpen: true,
								viewAccount: true
							})}
						>
							Sign In
						</Button>
					</div>
				}
				<Button
					variant={"outlined"}
					color={"primary"}
					className={classes.button}
					onClick={() => this.setState({ settingsOpen: true })}
				>
					Settings
      			</Button>

				{
					this.state.settingsOpen &&
					<Settings
						open={this.state.settingsOpen}
						viewAccount={this.state.viewAccount}
						close={() => this.setState({
							settingsOpen: false,
							viewAccount: false
						})}
					/>
				}

				{
					this.state.highScoresOpen &&
					<HighScores
						open={this.state.highScoresOpen}
						close={() => this.setState({ highScoresOpen: false })}
					/>
				}
				<Typography style={{ color: "#ffffff22", alignSelf: "end" }} variant={"caption"}>{version}</Typography>
			</div >
		);
	}

	private getDisabled() {
		const { rows, cols, mines } = this.appContext;
		return rows < min ||
			rows > maxRows ||
			cols < min ||
			cols > maxCols ||
			mines < min ||
			mines > maxMines ||
			isNaN(cols) ||
			isNaN(rows) ||
			isNaN(mines);
	}
}

export default withStyles(styles)(GameMenu);