import { Button, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Switch, TextField, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import firebase from 'firebase/app';
import 'firebase/auth';
import React from 'react';
import { AppContext, IAppContext } from "../App/app.context";

const styles = (theme: Theme) => createStyles({
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
	},
	button: {
		margin: theme.spacing.unit * 2,
	},
	formControl: {
		margin: theme.spacing.unit * 2,
	},
	group: {
		margin: 0,
	},
	settings: {
		display: "grid",
		padding: "0 3rem"
	},
	label: {
		paddingTop: "2rem",
	},
	sendButton: {
		justifySelf: "end"
	}
});

export interface IMenuProps extends WithStyles<typeof styles> {
	open: boolean;
	viewAccount: boolean;
	close(): void;
}

export interface IMenuState {
	email: string;
	linkSent: boolean;
	name: string;
	viewAccount: boolean;
}

export class GameMenu extends React.Component<IMenuProps, IMenuState> {
	public static contextType = AppContext;
	constructor(props: IMenuProps, state?: IMenuState) {
		super(props);
		this.state = {
			email: "",
			name: "",
			linkSent: false,
			viewAccount: props.viewAccount
		}
		this.appContext = this.context;
	}

	private appContext: IAppContext;

	public render() {
		this.appContext = this.context;
		const { rows, cols, mines, difficulty } = this.appContext;
		const { classes, open } = this.props;
		const { email, linkSent, name, viewAccount } = this.state;

		const maxRows = 30;
		const maxCols = 24;
		const maxMines = ((rows - 1) * (cols - 1));
		const min = 5;

		return (
			<Dialog
				fullScreen={true}
				open={open}
				onClose={() => this.closeModal()}
			>
				<DialogTitle>Settings</DialogTitle>

				<DialogContent className={classes.settings}>
					<FormLabel className={classes.label}>Controls</FormLabel>
					{
						window.navigator.vibrate
							?
							<FormControlLabel
								control={
									<Switch
										checked={this.appContext.vibration}
										onChange={(event, checked) => this.appContext.setVibration(checked)}
										value="gilad"
									/>
								}
								label="Vibration"
							/>
							: <Typography>Vibration not supported</Typography>
					}
					<FormLabel className={classes.label}>Difficulty</FormLabel>
					<FormControl className={classes.formControl}>
						<RadioGroup
							name="difficulty"
							className={classes.group}
							value={difficulty}
							onChange={(event, value) => this.appContext.difficultyChanged(value)}
						>
							<FormControlLabel value="beginner" control={<Radio />} label="Beginner" />
							<FormControlLabel value="intermediate" control={<Radio />} label="Intermediate" />
							<FormControlLabel value="expert" control={<Radio />} label="Expert" />
							<FormControlLabel value="custom" control={<Radio />} label="Custom" />
						</RadioGroup>
					</FormControl>
					<TextField
						id={"rows"}
						label={"Rows"}
						className={classes.textField}
						value={rows || ""}
						onChange={(event) => this.appContext.setRows(parseInt(event.target.value))}
						margin={"normal"}
						variant={"outlined"}
						type={"number"}
						disabled={difficulty !== "custom"}
						error={rows > maxRows || rows < min}
						helperText={rows > maxRows ? ("Maximum " + maxRows) : rows < min ? ("Minimum " + min) : ""}
					/>
					<TextField
						id={"cols"}
						label={"Columns"}
						className={classes.textField}
						value={cols || ""}
						onChange={(event) => this.appContext.setCols(parseInt(event.target.value))}
						margin={"normal"}
						variant={"outlined"}
						type={"number"}
						disabled={difficulty !== "custom"}
						error={cols > maxCols || cols < min}
						helperText={cols > maxCols ? ("Maximum " + maxCols) : cols < min ? ("Minimum " + min) : ""}
					/>
					<TextField
						id={"mines"}
						label={"Mines"}
						className={classes.textField}
						value={mines || ""}
						onChange={(event) => this.appContext.setMines(parseInt(event.target.value))}
						margin={"normal"}
						variant={"outlined"}
						type={"number"}
						disabled={difficulty !== "custom"}
						error={mines > maxMines || mines < min}
						helperText={mines > maxMines ? ("Maximum " + maxMines) : mines < min ? ("Minimum " + min) : ""}
					/>

					<FormLabel className={classes.label}>Account</FormLabel>
					{
						this.appContext.user &&
						<div style={{ display: "grid" }}>
							<Typography style={{ display: "grid", marginTop: "0.5rem", color: "#ffffffbb" }}>{this.appContext.user.displayName}</Typography>
							<Typography style={{ display: "grid", color: "#ffffff77" }}>{this.appContext.user.email}</Typography>
							<Button
								style={{ display: "grid", margin: "1rem" }}
								onClick={() => firebase.auth().signOut()}
								color="secondary"
							>
								Sign Out
            			</Button>
						</div>
					}
					<form autoComplete={"off"}>
						{
							!this.appContext.user && !linkSent &&

							<div style={{ display: "grid", justifyItems: "center" }}>
								<Typography style={{ padding: "1rem" }} variant={"body2"}>Sign in without a password using an email link, a single use link sent to your email.</Typography>
								<Typography style={{ padding: "1rem" }} variant={"caption"}>Signing in allows you to save your best score and post to the leaderboards!</Typography>
								<TextField
									id={"displayName"}
									label={"Name"}
									className={classes.textField}
									value={name || ""}
									onChange={(event) => this.setState({ name: event.target.value })}
									margin={"normal"}
									variant={"outlined"}
									error={name.length === 0}
									helperText={name.length === 0 ? "Please enter a name" : ""}
								/>
								<TextField
									id={"email"}
									label={"Email"}
									autoComplete={"off"}
									className={classes.textField}
									value={email || ""}
									onChange={(event) => this.setState({ email: event.target.value })}
									margin={"normal"}
									variant={"outlined"}
									error={!this.emailIsValid()}
									helperText={!this.emailIsValid() ? "Please enter a valid email address" : ""}
								/>
								{
									this.appContext.loading &&
									<CircularProgress />
								}
								<Button
									autoFocus={viewAccount}
									onFocus={() => this.setState({ viewAccount: false }, () => console.log("UNFOCUS"))}
									className={classes.sendButton}
									variant={"outlined"}
									onClick={() => this.sendLink()}
									color="secondary"
									disabled={!viewAccount && this.getDisabled()}
								>
									Send Link
            					</Button>
							</div>
						}
						{
							linkSent &&
							<Typography variant={"body1"}>An email link has been sent to {email}, please check your email and follow the instructions.</Typography>
						}
					</form>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => this.closeModal()} color="secondary">
						Close
            			</Button>
				</DialogActions>
			</Dialog>
		);
	}

	private closeModal() {
		if (this.state.linkSent) {
			this.setState({
				linkSent: false
			});
		}
		this.props.close();
	}

	private getDisabled() {
		const { name, email } = this.state;
		return this.appContext.loading ||
			!this.emailIsValid() ||
			email.length === 0 ||
			name.length === 0;
	}

	private async sendLink() {
		const actionCodeSettings: firebase.auth.ActionCodeSettings = {
			url: 'https://minesweeper.inprod.dev?email=' + this.state.email + "&name=" + this.state.name,
			handleCodeInApp: true
		};
		this.appContext.setLoading(true);
		try {
			await firebase.auth().sendSignInLinkToEmail(this.state.email, actionCodeSettings);
			this.setState({
				linkSent: true
			}, () => this.appContext.setLoading(false));

		} catch (error) {
			console.error(error);
			this.appContext.setLoading(false);
		}
	}

	private emailIsValid() {
		let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return regex.test(this.state.email) || this.state.email === "";
	}
}

export default withStyles(styles)(GameMenu);