import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import * as React from "react";
import { UserScore } from "./UserScores";

export interface IAppProviderState {
	rows: number;
	cols: number;
	mines: number;
	flags: number;
	loading: boolean;
	gameStarted: boolean;
	gameOver: boolean;
	gameWon: boolean;
	gameTime: number;
	startTime: number;
	vibration: boolean;
	user: firebase.User | null;
	beginner: UserScore;
	intermediate: UserScore;
	expert: UserScore;
	difficulty: string;
	primaryClick: "mine" | "flag";
	adventureMode: boolean;
}

export interface IAppContext extends IAppProviderState {
	setLoading(loading: boolean): void;
	startGame(adventureMode: boolean): void;
	setCols(cols: number): void;
	setRows(rows: number): void;
	setMines(mines: number): void;
	gameEnded(gameWon: boolean): void,
	updateFlags(flags: number): void;
	restartGame(): void;
	quitGame(): void;
	setVibration(checked: boolean): void;
	difficultyChanged(difficulty: string): void;
	togglePrimaryClick(): void;
}

export const AppContext = React.createContext<IAppContext>(
	{
		loading: false,
		cols: 0,
		rows: 0,
		mines: 0,
		gameTime: 0,
		flags: 0,
		gameStarted: false,
		gameOver: false,
		gameWon: false,
		user: null,
		beginner: {} as UserScore,
		intermediate: {} as UserScore,
		expert: {} as UserScore,
		vibration: true,
		difficulty: "beginner",
		primaryClick: "mine",
		startTime: Date.now(),
		adventureMode: false,
		setLoading: () => null,
		startGame: () => null,
		setCols: () => null,
		setRows: () => null,
		setMines: () => null,
		gameEnded: () => null,
		updateFlags: () => null,
		restartGame: () => null,
		quitGame: () => null,
		setVibration: () => null,
		difficultyChanged: () => null,
		togglePrimaryClick: () => null
	}
);


export interface IAppProviderProps {

}

export default class AppProvider extends React.Component<IAppProviderProps, IAppProviderState> {
	constructor(props: IAppProviderProps, state: IAppProviderState) {
		super(props);
		this.state = {
			loading: false,
			cols: 10,
			rows: 10,
			mines: 10,
			flags: 10,
			gameTime: 0,
			startTime: Date.now(),
			gameOver: false,
			gameWon: false,
			gameStarted: false,
			user: null,
			vibration: true,
			difficulty: "beginner",
			primaryClick: "mine",
			beginner: {} as UserScore,
			intermediate: {} as UserScore,
			expert: {} as UserScore,
			adventureMode: false
		}

		const config = {
			apiKey: "AIzaSyCI5kWDbhk--0b4GoA1kRUgwmAZU_mnJrg",
			authDomain: "minesweeper-2baff.firebaseapp.com",
			databaseURL: "https://minesweeper-2baff.firebaseio.com",
			projectId: "minesweeper-2baff"
		};
		firebase.initializeApp(config);
		this.unsub = () => null;
		this.beginner = () => null;
		this.intermediate = () => null;
		this.expert = () => null;
	}

	private unsub: firebase.Unsubscribe | null;
	private beginner: firebase.Unsubscribe | null;
	private intermediate: firebase.Unsubscribe | null;
	private expert: firebase.Unsubscribe | null;

	public componentDidMount(): void {
		let cols = parseInt(localStorage.getItem("cols") || "10");
		let rows = parseInt(localStorage.getItem("rows") || "10");
		let mines = parseInt(localStorage.getItem("mines") || "10");
		let vibrationString = localStorage.getItem("vibration") || "true";
		let vibration = vibrationString === "true" ? true : false;
		let difficulty = localStorage.getItem("difficulty") || "beginner";
		let primaryClick: "mine" | "flag" = localStorage.getItem("primaryClick") === "flag" ? "flag" : "mine";
		this.setState({
			cols,
			rows,
			mines,
			flags: mines,
			vibration,
			difficulty,
			primaryClick
		});
		this.signIn();
	}

	public componentWillUnmount() {
		if (this.unsub) {
			this.unsub();
		}
		if (this.beginner) {
			this.beginner();
		}
		if (this.intermediate) {
			this.intermediate();
		}
		if (this.expert) {
			this.expert();
		}
	}

	private timer: NodeJS.Timeout = setInterval(() => null, 1);

	public render() {
		return (
			<AppContext.Provider value={{
				...this.state,
				startGame: (adventureMode) => this.startGame(adventureMode),
				setCols: (cols) => this.setState({ cols }, () => localStorage.setItem("cols", cols.toString())),
				setRows: (rows) => this.setState({ rows }, () => localStorage.setItem("rows", rows.toString())),
				setMines: (mines) => this.setState({ mines }, () => localStorage.setItem("mines", mines.toString())),
				gameEnded: (win) => this.gameEnded(win),
				restartGame: () => this.restartGame(),
				updateFlags: (flags) => this.setState({ flags }),
				quitGame: () => this.quitGame(),
				setLoading: (loading) => this.setState({ loading }),
				setVibration: (vibration) => this.setState({ vibration }, () => localStorage.setItem("vibration", vibration.toString())),
				difficultyChanged: (difficulty) => this.setDifficulty(difficulty),
				togglePrimaryClick: () => this.togglePrimaryClick()
			}}>
				{this.props.children}
			</AppContext.Provider>
		);
	}

	private async signIn() {
		if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
			let url = new URL(window.location.href);
			let email = url.searchParams.get("email");
			let name = url.searchParams.get("name");
			let cred = await firebase.auth().signInWithEmailLink(email as string, window.location.href);
			if (cred.user)
				await cred.user.updateProfile({ displayName: name });
		}

		this.unsub = firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				this.beginner = firebase.firestore().collection("beginner").doc(user.uid).onSnapshot((snapshot) => this.onBeginnerScoreUpdated(snapshot));
				this.intermediate = firebase.firestore().collection("intermediate").doc(user.uid).onSnapshot((snapshot) => this.onIntermediateScoreUpdated(snapshot));
				this.expert = firebase.firestore().collection("expert").doc(user.uid).onSnapshot((snapshot) => this.onExpertScoreUpdated(snapshot));
			}
			this.setState({
				user
			});
		});
	}

	private onBeginnerScoreUpdated(snapshot: firebase.firestore.DocumentSnapshot) {
		let beginner = snapshot.data() as UserScore;
		this.setState({
			beginner
		});
	}

	private onIntermediateScoreUpdated(snapshot: firebase.firestore.DocumentSnapshot) {
		let intermediate = snapshot.data() as UserScore;
		this.setState({
			intermediate
		});
	}

	private onExpertScoreUpdated(snapshot: firebase.firestore.DocumentSnapshot) {
		let expert = snapshot.data() as UserScore;
		this.setState({
			expert
		});
	}

	private gameEnded(gameWon: boolean) {
		if (this.state.user) {
			let userScore = this.getBestScore();
			if (userScore) {
				let gameTime = this.state.gameTime / 1000;
				if (!this.state.adventureMode) {
					let score = (!userScore.score || (gameTime < userScore.score)) ? gameTime : userScore.score;
					firebase.firestore()
						.collection(this.state.difficulty)
						.doc(this.state.user.uid)
						.set(gameWon
							? {
								name: this.state.user.displayName,
								games: userScore.games + 1,
								score,
								timestamp: new Date(),
								wins: userScore.wins + 1
							}
							: {
								name: this.state.user.displayName,
								games: userScore.games + 1,
							}, { merge: true });
				} else if (gameWon) {
					let gameTime = this.state.gameTime / 1000;
					let adventureScore = (!userScore.adventureScore || (gameTime < userScore.adventureScore)) ? gameTime : userScore.adventureScore;
					firebase.firestore()
						.collection(this.state.difficulty)
						.doc(this.state.user.uid)
						.set({
							name: this.state.user.displayName,
							adventureScore,
							adventureTimestamp: new Date()
						}, { merge: true });
				}
			}
		}
		this.stopTimer();
		this.setState({
			gameOver: true,
			gameWon
		});
	}

	private getBestScore() {
		switch (this.state.difficulty) {
			case "beginner":
				return this.state.beginner;
			case "intermediate":
				return this.state.intermediate;
			case "expert":
				return this.state.expert;

			default:
				return null;
		}
	}

	private restartGame() {
		this.setState({
			gameStarted: false
		}, () => this.startGame(this.state.adventureMode));
	}

	private quitGame() {
		this.setState({
			gameStarted: false
		});
	}

	private startGame(adventureMode: boolean) {
		this.setState({
			gameTime: 0,
			gameStarted: true,
			gameOver: false,
			gameWon: false,
			flags: this.state.mines,
			adventureMode
		}, () => {
			this.stopTimer();
			this.setState({
				startTime: Date.now()
			});
			this.timer = setInterval(() => {
				this.setState({
					gameTime: Date.now() - this.state.startTime
				});
			}, 1);
		});
	}

	private stopTimer() {
		clearInterval(this.timer);
	}

	private setDifficulty(difficulty: string) {
		localStorage.setItem("difficulty", difficulty);
		if (difficulty === "beginner") {
			localStorage.setItem("cols", "9");
			localStorage.setItem("rows", "9");
			localStorage.setItem("mines", "10");
			this.setState({
				cols: 9,
				rows: 9,
				mines: 10,
				difficulty
			});
		} else if (difficulty === "intermediate") {
			localStorage.setItem("cols", "16");
			localStorage.setItem("rows", "16");
			localStorage.setItem("mines", "40");
			this.setState({
				cols: 16,
				rows: 16,
				mines: 40,
				difficulty
			});
		} else if (difficulty === "expert") {
			localStorage.setItem("cols", "16");
			localStorage.setItem("rows", "30");
			localStorage.setItem("mines", "99");
			this.setState({
				cols: 16,
				rows: 30,
				mines: 99,
				difficulty
			});
		} else if (difficulty === "custom") {
			this.setState({
				difficulty
			});
		}
	}

	private togglePrimaryClick() {
		let primaryClick = this.state.primaryClick;
		primaryClick = primaryClick === "mine" ? "flag" : "mine";
		localStorage.setItem("primaryClick", primaryClick);
		this.setState({
			primaryClick
		});
	}
}