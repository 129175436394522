export class SquareInfo {
	public row: number;
	public column: number;
	public index: number;
	public isMine: boolean;
	public hasFlag: boolean;
	public touches: number;
	public clicked: boolean;
	public exploded: boolean;
	public checked: boolean;
	public parent: SquareInfo;
	public showPath: boolean;
	constructor(item?: any) {
		this.row = item.row !== undefined ? item.row : null;
		this.column = item.column !== undefined ? item.column : null;
		this.index = item.index !== undefined ? item.index : null;
		this.isMine = item.isMine !== undefined ? item.isMine : null;
		this.hasFlag = item.hasFlag !== undefined ? item.hasFlag : null;
		this.touches = item.touches !== undefined ? item.touches : null;
		this.clicked = item.clicked !== undefined ? item.clicked : null;
		this.exploded = item.exploded !== undefined ? item.exploded : null;
		this.checked = item.checked !== undefined ? item.checked : null;
		this.parent = item.parent !== undefined ? item.parent : null;
		this.showPath = item.showPath !== undefined ? item.showPath : null;
	}
}