import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar, SnackbarContent, SvgIcon, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { green, pink } from "@material-ui/core/colors";
import CloseIcon from '@material-ui/icons/Close';
import FlagIcon from '@material-ui/icons/Flag';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import WinIcon from '@material-ui/icons/Mood';
import LoseIcon from '@material-ui/icons/MoodBad';
import TimerIcon from '@material-ui/icons/Timer';
import React from 'react';
import { AppContext, IAppContext } from "../App/app.context";
const bomb = "M11.25,6A3.25,3.25 0 0,1 14.5,2.75A3.25,3.25 0 0,1 17.75,6C17.75,6.42 18.08,6.75 18.5,6.75C18.92,6.75 19.25,6.42 19.25,6V5.25H20.75V6A2.25,2.25 0 0,1 18.5,8.25A2.25,2.25 0 0,1 16.25,6A1.75,1.75 0 0,0 14.5,4.25A1.75,1.75 0 0,0 12.75,6H14V7.29C16.89,8.15 19,10.83 19,14A7,7 0 0,1 12,21A7,7 0 0,1 5,14C5,10.83 7.11,8.15 10,7.29V6H11.25M22,6H24V7H22V6M19,4V2H20V4H19M20.91,4.38L22.33,2.96L23.04,3.67L21.62,5.09L20.91,4.38Z";
const mine = "M23,13V11H19.93C19.75,9.58 19.19,8.23 18.31,7.1L20.5,4.93L19.07,3.5L16.9,5.69C15.77,4.81 14.42,4.25 13,4.07V1H11V4.07C9.58,4.25 8.23,4.81 7.1,5.69L4.93,3.5L3.5,4.93L5.69,7.1C4.81,8.23 4.25,9.58 4.07,11H1V13H4.07C4.25,14.42 4.81,15.77 5.69,16.9L3.5,19.07L4.93,20.5L7.1,18.31C8.23,19.19 9.58,19.75 11,19.93V23H13V19.93C14.42,19.75 15.77,19.19 16.9,18.31L19.07,20.5L20.5,19.07L18.31,16.9C19.19,15.77 19.75,14.42 19.93,13H23M12,8A4,4 0 0,0 8,12H6A6,6 0 0,1 12,6V8Z";
const trophy = "M7,2V4H2V11C2,12 3,13 4,13H7.2C7.6,14.9 8.6,16.6 11,16.9V19C8,19.2 8,20.3 8,21.6V22H16V21.7C16,20.4 16,19.3 13,19.1V17C15.5,16.7 16.5,15 16.8,13.1H20C21,13.1 22,12.1 22,11.1V4H17V2H7M9,4H15V12A3,3 0 0,1 12,15C10,15 9,13.66 9,12V4M4,6H7V8L7,11H4V6M17,6H20V11H17V6Z";
const styles = (theme: Theme) => createStyles({
	close: {
		padding: theme.spacing.unit / 2,
	},
	win: {
		backgroundColor: green[600],
	},
	lose: {
		backgroundColor: theme.palette.error.dark,
	},
	message: {
		display: 'flex',
		alignItems: 'center',
		color: "white"
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing.unit,
	},
	icon: {
		fontSize: 20,
		color: "white"
	},
});

export interface IControlBarProps extends WithStyles<typeof styles> {

}

export interface IControlBarState {
	openAlert: boolean;
	snackOpen: boolean;
}

export class ControlBar extends React.Component<IControlBarProps, IControlBarState> {
	public static contextType = AppContext;
	constructor(props: IControlBarProps, state?: IControlBarState) {
		super(props);
		this.state = {
			openAlert: false,
			snackOpen: true
		}
	}

	public render() {
		let appContext: IAppContext = this.context;
		const { openAlert } = this.state;
		const { classes } = this.props;
		const Icon = appContext.gameWon ? WinIcon : LoseIcon;
		return (
			<div style={{
				display: "grid",
				gridTemplateColumns: "50px 50px 1fr 1fr",
				gridTemplateRows: "3rem",
				gridTemplateAreas: "'menu toggle flags time'",
				alignItems: "center",
				padding: "0.5rem"
			}}>
				<IconButton
					style={{ gridArea: "menu" }}
					onClick={() => this.setState({ openAlert: true })}
				>
					<MenuIcon />
				</IconButton>
				<IconButton
					color={"default"}
					style={{
						gridArea: "toggle",
						boxShadow: "0px 0px 4px 1px #00000077",
						height: "2rem",
						width: "2rem",
						padding: 0,
						justifySelf: "center",
						backgroundColor: "#444"
					}}
					onClick={() => appContext.togglePrimaryClick()}
					disabled={appContext.gameOver}
				>
					{
						appContext.primaryClick === "mine"
							? <SvgIcon color={"disabled"} style={{ justifySelf: "center" }}>
								<path d={bomb} />
							</SvgIcon>
							: <FlagOutlinedIcon color={"disabled"} style={{ justifySelf: "center" }} />
					}

				</IconButton>
				<div style={{
					display: "grid",
					alignItems: "center",
					justifyContent: "start",
					gridArea: "flags",
				}}>
					<FlagIcon style={{ gridRow: 1, marginRight: "0.5rem" }} color={"disabled"} />
					<Typography style={{ gridRow: 1 }} variant={"body1"} color={"primary"}>{appContext.flags}</Typography>
				</div>
				<div style={{
					display: "grid",
					alignItems: "center",
					justifyContent: "end",
					gridArea: "time"
				}}>
					<Typography style={{ color: pink[700], gridRow: 1 }} variant={"body1"}>{(appContext.gameTime / 1000).toFixed(3)}</Typography>
					<TimerIcon style={{ gridRow: 1, marginLeft: "0.5rem" }} color={"disabled"} />
				</div>

				<Dialog
					open={openAlert}
					onClose={() => this.closeAlert()}
				>
					<DialogTitle>Menu</DialogTitle>
					<DialogContent style={{ display: "grid", height: "15rem", width: "15rem" }}>
						<Button
							style={{ margin: "1rem" }}
							variant={"outlined"}
							onClick={() => appContext.restartGame()}
							color={"default"}
						>
							Restart
            			</Button>
						<Button
							style={{ margin: "1rem" }}
							variant={"outlined"}
							onClick={() => appContext.quitGame()}
							color={"secondary"}
						>
							Quit
            			</Button>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => this.closeAlert()}
							color={"default"}
						>
							Cancel
           				</Button>
					</DialogActions>
				</Dialog>
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					open={this.state.snackOpen && appContext.gameOver}
					onClose={(event, reason) => this.closeSnack(reason)}
				>
					<SnackbarContent
						className={classes[appContext.gameWon ? "win" : "lose"]}
						message={<span className={classes.message}>
							<Icon className={classes.icon + " " + classes.iconVariant} />
							{appContext.gameWon ? "Winner Winner, Chicken Dinner!" : "Game Over!"}
						</span>}
						action={[
							<Button
								onClick={() => appContext.restartGame()}
								key={"restart"}
								size={"large"}
							>
								Restart
  						</Button>,
							<Button
								onClick={() => appContext.quitGame()}
								key={"menu"}
								size={"small"}
							>
								Menu
  						</Button>,
							<IconButton
								key="close"
								color={"default"}
								className={classes.close}
								onClick={() => this.closeSnack("clickedClose")}
							>
								<CloseIcon className={classes.icon} />
							</IconButton>
						]}
					/>
				</Snackbar>
			</div>
		);
	}

	private closeSnack(reason: string) {
		if (reason === "clickaway") {
			return;
		}
		this.setState({
			snackOpen: false
		});
	}

	private closeAlert() {
		this.setState({
			openAlert: false
		});
	}

	private getBestScore() {
		let appContext: IAppContext = this.context;
		switch (appContext.difficulty) {
			case "beginner":
				return appContext.beginner.score;
			case "intermediate":
				return appContext.intermediate.score;
			case "expert":
				return appContext.expert.score;

			default:
				return 0;
		}
	}
}

export default withStyles(styles)(ControlBar);