import { SquareInfo } from "../Square/SquareInfo";

export default class BreadthFirstSearch {
	private columns: number = 0;
	private rows: number = 0;
	private squares: SquareInfo[] = [];

	private getSurrounding(square: SquareInfo) {
		return [
			[square.column, square.row + 1],
			[square.column, square.row - 1],
			[square.column + 1, square.row],
			[square.column - 1, square.row],
		];

	}
	private isValidPoint(square: SquareInfo) {
		if (square.isMine) return false;
		return (square.column >= 0) && (square.column < this.columns) &&
			(square.row >= 0) && (square.column < this.rows);
	}

	private getFutures(currents: SquareInfo[]) {
		const nexts: SquareInfo[] = [];
		for (let i = 0; i < currents.length; i++) {
			let surrounding = this.getSurrounding(currents[i]);
			for (let j = 0; j < surrounding.length; j++) {
				let coord = surrounding[j];
				let s = this.squares.find((square) => coord[0] === square.column && coord[1] === square.row);
				if (s && this.isValidPoint(s)) {
					if (!s.checked) {
						s.checked = true;
						s.parent = currents[i];
						nexts.push(s);
					}
				}
			}
		}
		return nexts;
	}

	public isBoardSolvable(squares: SquareInfo[], columns: number, rows: number) {
		this.columns = columns;
		this.rows = rows;
		this.squares = squares;

		let goal = this.squares[squares.length - 1];
		let futures = [this.squares[0]];


		while (futures.length > 0) {
			let square = futures[0];
			if ((square.column === goal.column) && (square.row === goal.row)) {
				return this.getSolvedPath();
			}
			futures = this.getFutures(futures);
		}
		return [];
	}

	private getSolvedPath() {
		let square = this.squares[this.squares.length - 1].parent;
		let path = [];
		while (square.index > 0) {
			square.showPath = true;
			path.push(square);
			square = square.parent;
		}
		square = this.squares[0]
		square.showPath = true;
		path.push(square);
		return path;
	}
}